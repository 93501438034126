// If you comment out code below, bootstrap will use red as primary color
// and btn-primary will become red

// $primary: red;

@import "~bootstrap/scss/bootstrap.scss";

.jumbotron {
  // should be relative path of the entry scss file
  background-image: url("../../vendors/images/sample.jpg");
  background-size: cover;
}

.html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

.menu-btn {
  padding: 1rem 3rem;
  border-radius: 45px;
  background-color: #0bacda;
  color: white;
  font-size: 30px;
  text-align: center;
  line-height: 50px;
  cursor: pointer;
  text-decoration: none;
  margin: 1rem;
  text-transform: uppercase;
}

.stats-container {
  max-width: 1100px;
  padding: 1rem;
}

table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;

  td, th {
    border: 1px solid #ddd;
    padding: 8px;
  }

  tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  tr:hover {
    background-color: #ddd;
  }

  th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #0086b1;
    color: white;
  }
}
